import React from 'react'

interface OpenLinkProps {
    height?: string;
    width?: string;
}

const OpenLink = ({height="8", width="8"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.130244 7.24089L6.48282 0.888862H1.77797C1.6601 0.888862 1.54706 0.842038 1.46371 0.758691C1.38036 0.675344 1.33354 0.562301 1.33354 0.444431C1.33354 0.32656 1.38036 0.213518 1.46371 0.130171C1.54706 0.0468235 1.6601 4.76837e-07 1.77797 4.76837e-07H7.55557C7.67344 4.76837e-07 7.78648 0.0468235 7.86983 0.130171C7.95318 0.213518 8 0.32656 8 0.444431V6.22203C8 6.3399 7.95318 6.45294 7.86983 6.53629C7.78648 6.61964 7.67344 6.66646 7.55557 6.66646C7.4377 6.66646 7.32466 6.61964 7.24131 6.53629C7.15796 6.45294 7.11114 6.3399 7.11114 6.22203V1.51718L0.759113 7.86976C0.717821 7.91105 0.6688 7.9438 0.61485 7.96615C0.560899 7.9885 0.503074 8 0.444678 8C0.386282 8 0.328458 7.9885 0.274508 7.96615C0.220557 7.9438 0.171535 7.91105 0.130244 7.86976C0.0889516 7.82846 0.0561972 7.77944 0.0338502 7.72549C0.0115027 7.67154 4.76837e-07 7.61372 4.76837e-07 7.55532C4.76837e-07 7.49693 0.0115027 7.4391 0.0338502 7.38515C0.0561972 7.3312 0.0889516 7.28218 0.130244 7.24089Z" fill="#D53A94" />
        </svg>
    )
}

export default OpenLink