import React from 'react';

export const AcceptRequest = ({ height, width, color }: { height?: string, width?: string, color?: string }) => {
    return (
        < svg
            width={width || "48"}
            height={height || "48"}
            viewBox = "0 0 48 48"
            fill = "none"
            xmlns = "http://www.w3.org/2000/svg"
        >
            <path d="M24 4.5C20.1433 4.5 16.3731 5.64366 13.1664 7.78634C9.95963 9.92903 7.46027 12.9745 5.98436 16.5377C4.50845 20.1008 4.12228 24.0216 4.8747 27.8043C5.62711 31.5869 7.4843 35.0615 10.2114 37.7886C12.9386 40.5157 16.4131 42.3729 20.1957 43.1253C23.9784 43.8777 27.8992 43.4916 31.4623 42.0156C35.0255 40.5397 38.071 38.0404 40.2137 34.8336C42.3564 31.6269 43.5 27.8567 43.5 24C43.4901 18.8313 41.4325 13.8772 37.7776 10.2224C34.1228 6.56755 29.1687 4.50991 24 4.5ZM33.2813 20.5875L22.2938 31.0875C22.0097 31.3547 21.6337 31.5024 21.2438 31.5C21.0531 31.5027 20.8639 31.4677 20.6869 31.3969C20.5099 31.3261 20.3487 31.2209 20.2125 31.0875L14.7188 25.8375C14.5664 25.7046 14.4425 25.5422 14.3544 25.3601C14.2664 25.1781 14.2161 24.9801 14.2066 24.7781C14.197 24.5761 14.2284 24.3743 14.2988 24.1847C14.3693 23.9952 14.4773 23.8218 14.6164 23.6751C14.7556 23.5283 14.923 23.4113 15.1085 23.3309C15.2941 23.2505 15.494 23.2084 15.6962 23.2072C15.8984 23.2061 16.0988 23.2458 16.2852 23.324C16.4717 23.4023 16.6404 23.5174 16.7813 23.6625L21.2438 27.9187L31.2188 18.4125C31.5104 18.158 31.8891 18.0262 32.2758 18.0445C32.6624 18.0628 33.027 18.2298 33.2933 18.5107C33.5597 18.7915 33.7071 19.1644 33.7049 19.5515C33.7026 19.9386 33.5508 20.3098 33.2813 20.5875Z" fill="#30CC8B"/>
        </svg>
    );
};
