import React from 'react';

export const NoEncryptionIcon = ({
  size,
}: {
  size?: string;
}) => {
  return (
    <svg width={size??"21"} height={size??"20"} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="no_encryption">
<path id="Vector" d="M17.1668 14.2913L15.5002 12.6247V8.33301H11.2085L9.54183 6.66634H13.0002V4.99967C13.0002 4.30523 12.7606 3.71495 12.2814 3.22884C11.8022 2.74273 11.2154 2.49967 10.521 2.49967C9.82655 2.49967 9.24322 2.73926 8.771 3.21842C8.29877 3.69759 8.06266 4.29134 8.06266 4.99967V5.18717L6.54183 3.66634C6.84738 2.81912 7.36822 2.13509 8.10433 1.61426C8.84044 1.09342 9.646 0.833008 10.521 0.833008C11.6738 0.833008 12.6529 1.23926 13.4585 2.05176C14.2641 2.86426 14.6668 3.8469 14.6668 4.99967V6.66634H15.5002C15.9585 6.66634 16.3509 6.82954 16.6772 7.15592C17.0036 7.48231 17.1668 7.87467 17.1668 8.33301V14.2913ZM17.5835 19.4163L16.2918 18.1247C16.1391 18.1941 16.0002 18.2462 15.8752 18.2809C15.7502 18.3156 15.6252 18.333 15.5002 18.333H5.50016C5.04183 18.333 4.64947 18.1698 4.32308 17.8434C3.99669 17.517 3.8335 17.1247 3.8335 16.6663V8.33301C3.8335 7.98579 3.93419 7.66634 4.13558 7.37467C4.33697 7.08301 4.59738 6.87467 4.91683 6.74967L1.0835 2.91634L2.25016 1.74967L18.7502 18.2497L17.5835 19.4163ZM14.7918 16.6663L11.7293 13.5622C11.5766 13.715 11.3995 13.833 11.1981 13.9163C10.9967 13.9997 10.7779 14.0413 10.5418 14.0413C10.0835 14.0413 9.69113 13.8781 9.36475 13.5518C9.03836 13.2254 8.87516 12.833 8.87516 12.3747C8.87516 12.1386 8.91683 11.9198 9.00016 11.7184C9.0835 11.517 9.20155 11.34 9.35433 11.1872L6.4585 8.33301H5.50016V16.6663H14.7918Z" fill="#62626A"/>
</g>
</svg>

    
    
  );
};