import React from 'react';

export const AddUserDarkIcon = () => {
    return (
        <svg width="49" height="21" viewBox="0 0 49 21" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
            <rect width="49" height="21" rx="6" fill="#282A2E" />
            <path d="M13.86 14L10.656 5.6H9.348L6.084 14H7.428L8.076 12.26H11.868L12.516 14H13.86ZM9.972 7.208L11.424 11.096H8.52L9.972 7.208ZM19.0121 9.056C18.5201 8.648 17.8841 8.42 17.2361 8.42C15.6281 8.42 14.3561 9.68 14.3561 11.276C14.3561 12.884 15.6281 14.132 17.2361 14.132C17.8841 14.132 18.5201 13.904 19.0121 13.496V14H20.1521V5.6H19.0121V9.056ZM17.2841 13.028C16.2881 13.028 15.5561 12.284 15.5561 11.276C15.5561 10.268 16.2881 9.524 17.2841 9.512C18.2681 9.524 19.0121 10.268 19.0121 11.276C19.0121 12.284 18.2681 13.028 17.2841 13.028ZM26.1371 9.056C25.6451 8.648 25.0091 8.42 24.3611 8.42C22.7531 8.42 21.4811 9.68 21.4811 11.276C21.4811 12.884 22.7531 14.132 24.3611 14.132C25.0091 14.132 25.6451 13.904 26.1371 13.496V14H27.2771V5.6H26.1371V9.056ZM24.4091 13.028C23.4131 13.028 22.6811 12.284 22.6811 11.276C22.6811 10.268 23.4131 9.524 24.4091 9.512C25.3931 9.524 26.1371 10.268 26.1371 11.276C26.1371 12.284 25.3931 13.028 24.4091 13.028Z" fill="white" />
            <path d="M41.9062 10.5C41.9062 10.6243 41.8569 10.7435 41.769 10.8315C41.681 10.9194 41.5618 10.9688 41.4375 10.9688H38.4688V13.9375C38.4688 14.0618 38.4194 14.181 38.3315 14.269C38.2435 14.3569 38.1243 14.4062 38 14.4062C37.8757 14.4062 37.7565 14.3569 37.6685 14.269C37.5806 14.181 37.5312 14.0618 37.5312 13.9375V10.9688H34.5625C34.4382 10.9688 34.319 10.9194 34.231 10.8315C34.1431 10.7435 34.0938 10.6243 34.0938 10.5C34.0938 10.3757 34.1431 10.2565 34.231 10.1685C34.319 10.0806 34.4382 10.0312 34.5625 10.0312H37.5312V7.0625C37.5312 6.93818 37.5806 6.81895 37.6685 6.73104C37.7565 6.64314 37.8757 6.59375 38 6.59375C38.1243 6.59375 38.2435 6.64314 38.3315 6.73104C38.4194 6.81895 38.4688 6.93818 38.4688 7.0625V10.0312H41.4375C41.5618 10.0312 41.681 10.0806 41.769 10.1685C41.8569 10.2565 41.9062 10.3757 41.9062 10.5Z" fill="white" />
        </svg>

    );
};
