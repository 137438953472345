import React from 'react';

export const CloseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.625 4.375L4.375 15.625" stroke="#82828A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0799 15.4442L4.375 4.375" stroke="#82828A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
    
  );
};