import React from 'react';

export const ErrorSvg = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0ZM12 7C12 6.73478 12.1054 6.48043 12.2929 6.29289C12.4804 6.10536 12.7348 6 13 6C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15C12.7348 15 12.4804 14.8946 12.2929 14.7071C12.1054 14.5196 12 14.2652 12 14V7ZM13 20C12.7033 20 12.4133 19.912 12.1667 19.7472C11.92 19.5824 11.7277 19.3481 11.6142 19.074C11.5007 18.7999 11.471 18.4983 11.5288 18.2074C11.5867 17.9164 11.7296 17.6491 11.9393 17.4393C12.1491 17.2296 12.4164 17.0867 12.7074 17.0288C12.9983 16.9709 13.2999 17.0007 13.574 17.1142C13.8481 17.2277 14.0824 17.42 14.2472 17.6666C14.412 17.9133 14.5 18.2033 14.5 18.5C14.5 18.8978 14.342 19.2794 14.0607 19.5607C13.7794 19.842 13.3978 20 13 20Z"
        fill="#E93636"
      />
    </svg>
  );
};
