import React from 'react';

export const LinkIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5542 6.07818L11.1011 4.5313C11.756 3.93437 12.6158 3.61274 13.5017 3.63325C14.3876 3.65376 15.2315 4.01483 15.8581 4.64143C16.4847 5.26804 16.8458 6.11198 16.8663 6.9979C16.8868 7.88381 16.5652 8.74356 15.9683 9.39849L13.7573 11.6016C13.4387 11.9215 13.06 12.1752 12.6431 12.3484C12.2261 12.5216 11.7791 12.6107 11.3276 12.6107C10.8762 12.6107 10.4291 12.5216 10.0122 12.3484C9.59524 12.1752 9.21659 11.9215 8.89795 11.6016" stroke="#575D73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4454 14.9222L8.89849 16.469C8.24356 17.066 7.38381 17.3876 6.4979 17.3671C5.61198 17.3466 4.76804 16.9855 4.14143 16.3589C3.51483 15.7323 3.15376 14.8884 3.13325 14.0024C3.11274 13.1165 3.43437 12.2568 4.0313 11.6018L6.24224 9.39872C6.56088 9.07889 6.93954 8.82511 7.35648 8.65195C7.77342 8.47879 8.22046 8.38965 8.67193 8.38965C9.1234 8.38965 9.57043 8.47879 9.98738 8.65195C10.4043 8.82511 10.783 9.07889 11.1016 9.39872" stroke="#575D73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};