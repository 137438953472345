import React from 'react';

export const PolygonzkevmSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10279_32011)">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#7B3FE4"/>
    <path d="M18.4908 6.848L13.2428 3.81333C12.8641 3.6 12.4428 3.48267 12.0054 3.48267C11.5681 3.48267 11.1468 3.6 10.7734 3.81333L5.52542 6.848C5.15209 7.06667 4.83742 7.376 4.61875 7.75467C4.40009 8.128 4.28809 8.55467 4.28809 8.992V15.072C4.29342 15.504 4.40542 15.9253 4.62409 16.2987C4.84275 16.672 5.15209 16.9813 5.52542 17.1947L10.7734 20.2293C11.1468 20.448 11.5734 20.56 12.0108 20.56C12.4481 20.56 12.8694 20.448 13.2481 20.2293L18.4961 17.1947C18.8694 16.976 19.1841 16.6667 19.4028 16.288C19.6214 15.9147 19.7334 15.488 19.7334 15.0507V8.97067C19.7281 8.53867 19.6161 8.11733 19.3974 7.744C19.1734 7.37067 18.8641 7.06133 18.4908 6.848ZM5.52009 8.98667C5.52009 8.768 5.57875 8.55467 5.68542 8.368C5.79209 8.18133 5.95209 8.02133 6.13875 7.91467L11.3868 4.88533C11.5734 4.77867 11.7868 4.72 12.0054 4.72C12.2241 4.72 12.4374 4.77867 12.6241 4.88533L17.8774 7.91467C18.0641 8.02133 18.2188 8.176 18.3254 8.35733C18.4321 8.544 18.4908 8.752 18.4961 8.96533V9.584C18.4961 10.064 18.3041 10.528 17.9628 10.864C17.6214 11.2053 17.1628 11.3973 16.6828 11.3973H7.33875C6.68275 11.3973 6.04809 11.6053 5.52009 12V8.98667ZM18.4908 15.0507C18.4908 15.2693 18.4321 15.4827 18.3254 15.6693C18.2188 15.856 18.0588 16.016 17.8721 16.1227L12.6188 19.152C12.4321 19.2587 12.2188 19.3173 12.0001 19.3173C11.7814 19.3173 11.5681 19.2587 11.3814 19.152L6.13342 16.1227C5.94675 16.016 5.79209 15.8613 5.68542 15.68C5.57875 15.4933 5.52009 15.2853 5.51475 15.072V14.4533C5.51475 13.9733 5.70675 13.5093 6.04809 13.1733C6.38942 12.832 6.84809 12.64 7.32809 12.64H16.6668C17.3228 12.64 17.9574 12.432 18.4854 12.0373V15.0507H18.4908Z" fill="white"/>
    <path d="M9.13577 8.67727H10.3358V10.4693H11.5731V8.67727C11.5731 8.35194 11.4451 8.03727 11.2104 7.80261C10.9811 7.57327 10.6664 7.43994 10.3358 7.43994H9.13577C8.81044 7.43994 8.49577 7.56794 8.2611 7.80261C8.03177 8.03194 7.89844 8.34661 7.89844 8.67727V10.4693H9.13577V8.67727Z" fill="white"/>
    <path d="M10.3309 15.3601H9.13089V13.5681H7.89355V15.3601C7.89355 15.6854 8.02155 16.0001 8.25622 16.2348C8.48555 16.4641 8.80022 16.5974 9.13089 16.5974H10.3309C10.6562 16.5974 10.9709 16.4694 11.2056 16.2348C11.4349 16.0054 11.5682 15.6908 11.5682 15.3601V13.5681H10.3309V15.3601Z" fill="white"/>
    <path d="M14 8.67727H15.2V10.4693H16.4374V8.67727C16.4374 8.35194 16.3094 8.03727 16.0747 7.80261C15.8454 7.57327 15.5307 7.43994 15.2 7.43994H14C13.6747 7.43994 13.36 7.56794 13.1254 7.80261C12.896 8.03194 12.7627 8.34661 12.7627 8.67727V10.4693H14V8.67727Z" fill="white"/>
    <path d="M15.2054 15.3601H14.0054V13.5681H12.7681V15.3601C12.7681 15.6854 12.8961 16.0001 13.1307 16.2348C13.3601 16.4641 13.6747 16.5974 14.0054 16.5974H15.2054C15.5307 16.5974 15.8454 16.4694 16.0801 16.2348C16.3094 16.0054 16.4427 15.6908 16.4427 15.3601V13.5681H15.2054V15.3601Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_10279_32011">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    
    
    
  );
};