import React from 'react';

export const EncryptionIcon = ({
  size,
}: {
  size?: string;
}) => {
  return (
    <svg width={size??'25'} height={size??'25'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="lock_FILL0_wght500_GRAD0_opsz48 1">
    <path id="Vector" d="M4.62836 18.4476C4.23803 18.4476 3.90388 18.3086 3.62592 18.0307C3.34796 17.7527 3.20898 17.4186 3.20898 17.0283V8.07625C3.20898 7.68455 3.34796 7.34923 3.62592 7.07029C3.90388 6.79135 4.23803 6.65188 4.62836 6.65188H5.98707V4.76148C5.98707 3.64529 6.3778 2.69489 7.15926 1.91029C7.94073 1.1257 8.88805 0.733398 10.0012 0.733398C11.1144 0.733398 12.0613 1.1257 12.8421 1.91029C13.6229 2.69489 14.0133 3.64529 14.0133 4.76148V6.65188H15.372C15.7637 6.65188 16.099 6.79135 16.378 7.07029C16.6569 7.34923 16.7964 7.68455 16.7964 8.07625V17.0283C16.7964 17.4186 16.6569 17.7527 16.378 18.0307C16.099 18.3086 15.7637 18.4476 15.372 18.4476H4.62836ZM4.62836 17.0283H15.372V8.07625H4.62836V17.0283ZM10.0037 14.1564C10.4458 14.1564 10.8231 14.0034 11.1356 13.6974C11.4481 13.3914 11.6044 13.0236 11.6044 12.5939C11.6044 12.1773 11.4469 11.7988 11.1321 11.4585C10.8173 11.1182 10.4388 10.9481 9.99669 10.9481C9.55458 10.9481 9.17728 11.1182 8.86478 11.4585C8.55228 11.7988 8.39603 12.1807 8.39603 12.6043C8.39603 13.0279 8.55344 13.3925 8.86828 13.6981C9.18311 14.0036 9.56158 14.1564 10.0037 14.1564ZM7.40644 6.65188H12.5939V4.76263C12.5939 4.03502 12.3429 3.41897 11.8408 2.91448C11.3387 2.41001 10.7263 2.15777 10.0038 2.15777C9.28129 2.15777 8.66776 2.41001 8.16323 2.91448C7.65871 3.41897 7.40644 4.03502 7.40644 4.76263V6.65188Z" fill="#62626A"/>
    </g>
    </svg>
    
    
  );
};
