import React from 'react';

export const TheGraphSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6814_10094)">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#6747ED"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6606 12.7677C9.93715 12.7677 8.55353 11.3841 8.55353 9.6606C8.55353 7.93715 9.93715 6.55353 11.6606 6.55353C13.3841 6.55353 14.7677 7.93715 14.7677 9.6606C14.7677 11.3841 13.3841 12.7677 11.6606 12.7677ZM11.6606 5C14.2336 5 16.3212 7.08756 16.3212 9.6606C16.3212 12.2336 14.2336 14.3212 11.6606 14.3212C9.08756 14.3212 7 12.2336 7 9.6606C7 7.08756 9.08756 5 11.6606 5ZM16.1027 14.5639C16.4183 14.8795 16.4183 15.365 16.1027 15.6563L12.9957 18.7633C12.6801 19.0789 12.1946 19.0789 11.9033 18.7633C11.5878 18.4478 11.5878 17.9623 11.9033 17.671L15.0104 14.5639C15.3017 14.2484 15.8114 14.2484 16.1027 14.5639ZM17.899 5.77677C17.899 6.2137 17.5592 6.55353 17.1222 6.55353C16.6853 6.55353 16.3455 6.2137 16.3455 5.77677C16.3455 5.33984 16.6853 5 17.1222 5C17.5349 5 17.899 5.33984 17.899 5.77677Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_6814_10094">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    
    
    
    
  );
};