import * as React from 'react';

export const ArbitrumSvg = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 1080 1218.5"
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:#1b4add}'}</style>
    <path
      className="st0"
      d="M541.8 76.4c2.9 0 5.8.8 8.4 2.3l446.1 259.5c5.2 3 8.4 8.6 8.3 14.5l-1.7 516.1c0 6-3.2 11.5-8.4 14.5l-447.8 256.6c-2.5 1.5-5.5 2.2-8.4 2.2-2.9 0-5.8-.8-8.4-2.3L83.8 880.3c-5.2-3-8.4-8.6-8.3-14.5l1.7-516.1c0-6 3.2-11.5 8.4-14.5L533.4 78.6c2.5-1.5 5.4-2.2 8.4-2.2M542 1c-15.9-.1-31.8 4-46.1 12.2L48.1 269.7c-28.6 16.4-46.2 46.7-46.4 79.7L0 865.5c-.1 32.9 17.4 63.4 45.8 80L491.9 1205c14.2 8.3 30.1 12.4 46.1 12.5 15.9.1 31.8-4 46.1-12.2l447.8-256.6c28.6-16.4 46.2-46.7 46.4-79.7l1.7-516c.1-32.9-17.4-63.4-45.8-80L588.1 13.5C573.8 5.2 557.9 1 542 1z"
    />
    <path
      className="st0"
      d="M632.4 282.3H567c-4.9 0-9.3 3.1-11 7.7L345.8 866.4c-1.4 3.8 1.4 7.8 5.5 7.8h65.4c4.9 0 9.3-3.1 11-7.7L638 290c1.2-3.7-1.6-7.7-5.6-7.7zm-114.4 0h-65.4c-4.9 0-9.3 3.1-11 7.7L231.4 866.4c-1.4 3.8 1.4 7.8 5.5 7.8h65.4c4.9 0 9.3-3.1 11-7.7L523.6 290c1.3-3.7-1.5-7.7-5.6-7.7zm84.7 223.5c-1.9-5.1-9.1-5.1-11 0l-34 93.2c-.9 2.6-.9 5.4 0 8l94.7 259.6c1.7 4.6 6.1 7.7 11 7.7h65.4c4.1 0 6.9-4 5.5-7.8L602.7 505.8zm245.9 360.6L659.9 349.1c-1.9-5.1-9.1-5.1-11 0l-34 93.2c-.9 2.6-.9 5.4 0 8l151.9 416.3c1.7 4.6 6.1 7.7 11 7.7h65.4c3.9-.1 6.8-4.1 5.4-7.9z"
    />
  </svg>
);

